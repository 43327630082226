<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Alunos">
      <datatable-list
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        :showSelect="showSelect"
        :tableUser="true"
      ></datatable-list>
    </base-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    page: {
      title: 'Alunos'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Alunos',
        disabled: false,
        to: '/student/list'
      }
    ],
    text: {
      searchLabel: 'Pesquisar Alunos',
      emptyLabel: 'Nenhum aluno encontrado',
      addLabel: 'Adicionar Aluno',
      addUrl: '/school/add',
      importLabel: 'Importar Alunos',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        align: 'start',
        value: 'name'
      },
      { text: 'Escolas', value: 'schoolName' },
      { text: 'Nº Turmas', value: 'numberOfClass' }
    ],
    sortBy: 'name',
    showSelect: true,
    items: [
      {
        id: 1,
        schoolName: [
          'Escola Municipal Batel'
        ],
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        name: 'Lucinéia Percigili',
        email: 'embatel@sme.curitiba.pr.gov.br',
        numberOfClass: 'Turma 345',
        url: '/user/profile'
      },
      {
        id: 2,
        schoolName: 'Escola Municipal Batel',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        name: 'Josélia Percigili',
        email: 'embatel@sme.curitiba.pr.gov.br',
        numberOfClass: 'Turma 345',
        url: '/user/profile'
      }
    ]
  })
}
</script>
